<template>
    <div class="record-radio">
        <p class="record-radio-title">{{ title }}：</p>
        <el-radio v-for="(item, index) of itemArray" :key="index" v-model="selectedValue" :label="item.value">{{ item.label
        }}</el-radio>
    </div>
</template>

<script>
import { Radio } from "element-ui";
export default {

    components: {
        elRadio: Radio
    },

    model: {
        prop: "initSelectedValue",
        event: "onChanged"
    },


    props: {
        title: String,
        itemArray: Array,
        initSelectedValue: Number
    },

    data() {
        return {
            selectedValue: undefined
        }
    },

    mounted() {
        this.selectedValue = this.initSelectedValue
    },

    watch: {
        initSelectedValue(value) {
            this.selectedValue = value
        },

        selectedValue(value) {
            this.$emit("onChanged", value)
        }
    }

}
</script>

<style lang="scss" scoped>
.record-radio {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 28px;

    &-title {
        color: black;
        margin-right: 6px;
    }

    /deep/.el-radio__label{
        font-size: 16px;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner{
        border-color: #0052D9 !important;
        background: #0052D9 !important;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label{
        color: #606266 !important;
    }
}
</style>