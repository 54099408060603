<template>
    <div class="record-switch">
        <p class="record-switch-title">{{ title }}：</p>
        <el-switch v-model="isOn" active-color="#0052D9"></el-switch>
        <p class="record-switch-info">{{ statusInfo }}</p>
    </div>
</template>

<script>
import { Switch } from "element-ui";
export default {

    components: {
        elSwitch: Switch
    },

    model: {
        prop: "initIsOn",
        event: "onChanged"
    },


    props: {
        title: String,
        initIsOn: Boolean
    },

    data() {
        return {
            isOn: undefined

        }
    },

    computed: {
        statusInfo() {
            switch (this.isOn) {
                case true:
                    return "开启"
                case false:
                    return "关闭"
                default:
                    return null;
            }
        }
    },

    mounted() {
        this.isOn = this.initIsOn
    },

    watch: {
        initIsOn(value) {
            this.isOn = value
        },

        isOn(value) {
            this.$emit("onChanged", value)
        }
    }

}
</script>

<style lang="scss" scoped>
.record-switch {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 28px;

    &-title {
        color: black;
        margin-right: 6px;
    }

    &-info {
        color: rgba($color: #000000, $alpha: 0.4);
        margin-left: 14px;
    }
}
</style>